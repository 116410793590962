@font-face {
    font-family: 'TF2 Build';
    src: url('../fonts/tf2build.ttf');
}

html {
    min-height: 100%;
}

body {
    font-family: 'Helvetica', sans-serif;
    /* font-family: 'Century Schoolbook', serif; */
    margin: 0;
    background: -moz-linear-gradient(top, #b6b6b6, #5f5f5f);
}

a.unformatted-link {
    text-decoration: none;
    color: inherit;
}

a.unformatted-link:hover {
    cursor: pointer;
}

.header {
    top: 0px;
    height: 4em;
    padding: 1em;
    display: flex;
    gap: 2em;
    align-items: center;
    font-family: 'Century Schoolbook', serif;
    background: -moz-linear-gradient(top, #353535, #1a1a1a);
    color: #d1d1d1;
    border-bottom: solid black 2px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.header__item {
    font-size: large;
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.header__image {
    height: 1.7em;
}

.content {
    width: 70%;
    box-sizing: border-box;
    padding: 2em;
    background-color: #c0c0c0;
    border: 2px solid black;
    border-radius: 30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: auto;
    margin-top: -2px;
    margin-bottom: 2em;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.project-content p {
    text-align: justify;
}

/* .content {
    min-height: 50%;
} */

.project-content__img-container {
    height: 20em;
    float: right;
    margin: 2em;
    margin-top: 0;
}

.project-content__img-container img {
    height: inherit;
}

.dropdown {
    position: relative;
    display: inline-block;
    background-color: inherit;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.info-box {
    height: auto;
    padding: 1em;
    background-color: white;
    border-bottom-left-radius: 2em;
    border-bottom-right-radius: 2em;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.info-box > * {
    margin: 0;
}

.zoom {
    transition: .2s;
}

.zoom:hover {
    transform: scale(1.01);
}

.tf2 {
    font-family: 'TF2 Build', sans-serif;
}

#site-title {
    font-size: large;
    border-right: solid #d1d1d1 1px;
    padding-right: 1em;
}

#project-grid {
    display: flex;
    flex-flow: row wrap;
    gap: 2em;
    justify-content: space-evenly;
}

#project-grid > div {
    display: flex;
    flex-direction: column;
    flex-basis: min-content;
}

#project-grid a {
    aspect-ratio: 16 / 9;
    width: 30em;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background: black;
    opacity: 1;
    border-style: ridge;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

#project-grid a > img {
    width: 100%;
    object-fit: cover;
}

#project-grid a > img + p {
    all: unset;
    display: none;
    font-size: large;
    font-style: italic;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

#project-grid a > img + p::after {
    content: 'More info';
}

#project-grid a.zoom:hover > img + p {
    display: block;
}

#project-grid a.zoom:hover > img {
    opacity: 0.35;
    transition: 0.2s;
}
